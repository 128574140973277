@import "react-date-picker/dist/DatePicker.css";
@import "react-calendar/dist/Calendar.css";
@font-face {
  font-family: 'Work Sans';
  src: url('assets/fonts/WorkSans.ttf') format('truetype');
}
@font-face {
    font-family: 'Work Sans';
    src: url("assets/fonts/WorkSans-Medium.ttf") format('truetype');
    font-weight: 500;
}
@font-face {
    font-family: 'Work Sans';
    src: url("assets/fonts/WorkSans-SemiBold.ttf") format('truetype');
    font-weight: 600;
}
* {
    font-family: 'Work Sans', "Inter", sans-serif;
}

body {
    margin: 0;
}

/*Custom Scrollbar*/
/* width */
::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*Remove unnecessary highlights from all types on inputs*/
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

/* To remove counter on input type='number' */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
